<template>
  <div>
    <v-row justify="center">
      <v-col lg="12" cols="12">
        <homologation />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Settings",
  components: {
    Homologation: () =>
      import(
        "@/domains/therapist-settings/presentation/components/Homologation"
      ),
  },
  data: () => ({
    settings: {},
    checklist: {},
  }),
  methods: {},
};
</script>

<style scoped></style>
